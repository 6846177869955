@import "src/assets/scss/required";
@import "~bootstrap/scss/utilities/spacing";

.profilingstep {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    opacity: 0;
}

.progress {
    width: 300px;
    height: 6px;
    margin: auto;
    transform: translateY($padding/2);
}

.footerframe {
    width: 350px;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    button {
        width: 30%;
    }
}

.footer{
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background: $light;
    border-top: 1px solid rgba($info,.2);
    padding: $padding/2 0;
    display: flex;
    justify-content: center;
}

.option {
    display: flex;
    align-items: center;
    cursor: pointer;
    input {
        @extend .mr-3
    }
}

@include media-breakpoint-down(xs) {
    .footerframe {
        width: 100%;
        padding-left: $spacer;
        padding-right: $spacer;
    }
    .progress {
        width: 50%;
        height: 6px;
        margin: $spacer auto $spacer;
        transform: none;
    }
    .profilingstep {
        margin-bottom: $spacer*5;
    }
}