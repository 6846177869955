@import "src/assets/scss/customVariables";

.body {
  padding: $spacer * 2;

  .subscribe {
    font-weight: bold;
    letter-spacing: 0.02em;
  }

  .price {
    display: flex;
    align-items: center;

    .amount {
      font-size: $spacer * 1.3;
      font-weight: bold;
      margin-right: $spacer * 0.3;
    }

    .desc {
      font-size: $spacer * 1.3;
      opacity: 0.8;
    }
  }

  .planDesc {
    margin-top: $spacer;
    opacity: 0.6;
  }

  .subtotal {
    margin-top: $spacer;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .vat {
    margin-top: $spacer * 0.3;
    display: flex;
    align-items: center;
    justify-content: space-between;
    opacity: 0.6;
  }

  .total {
    margin-top: $spacer * 0.3;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .promoCode {
    margin-top: $spacer3;
    width: 11rem;
  }

  .billingDetails {
    margin-top: $spacer4 * 1.4;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      letter-spacing: 0.02em;

      .title {
        font-weight: bold;
      }

      .update {
        color: $primary;
        cursor: pointer;
        font-weight: bold;
        opacity: 0.8;

        &:hover {
          text-decoration: underline;
          opacity: 1;
        }
      }
    }

    .noBillingDetails {
      margin-top: $spacer2;
      opacity: 0.6;
    }

    .address {
      margin-top: $spacer2;
      opacity: 0.6;
    }

    .paymentMethod {
      margin-top: $spacer2;
      opacity: 0.6;
    }
  }

  .tax {
    margin-top: $spacer4 * 1.2;
    font-weight: bold;
  }

  .taxId {
    margin-top: $spacer3;
    width: 11rem;
  }

  .subscribeNote {
    margin-top: $spacer4 * 1.4;
  }

  .ctas {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: $spacer4;
  }
}
