@import "src/assets/scss/customVariables";

.content {
  padding: 3rem;

  .header {
    font-weight: bold;
    font-size: 1.5rem;
  }

  .plan {
    margin-top: $spacer4;

    .title {
      margin-bottom: $spacer3;
      font-weight: bold;
      font-size: 0.875rem;
    }

    .change {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .planName {
        font-weight: bold;
      }
    }

    .invoices {
      margin-top: $spacer * 0.5;
      opacity: 0.8;
      color: $primary;
      cursor: pointer;

      &:hover {
        opacity: 1;
        text-decoration: underline;
      }
    }
  }
}
