@import "src/assets/scss/customVariables";

.content {
  padding: 3rem;

  .header {
    font-weight: bold;
    font-size: 1.2rem;
  }

  .channels {
    margin-top: $spacer4;

    .noData {
    }

    .cta {
      color: $primary;
      cursor: pointer;
      font-weight: bold;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.viewer {
  padding: 2rem;

  .deploy, .user {
    .title {
      margin-bottom: $spacer2;
      font-weight: bold;

      .copy {
        margin-left: $spacer2;
        cursor: pointer;
        opacity: 0.8;

        &:hover {
          opacity: 1;
        }

        svg {
          path {
            stroke: $primary;
          }
        }
      }
    }

    .channel {
    }

    .msg {
      margin-bottom: $spacer4;
      opacity: 0.8;
      font-size: 0.8rem;
    }
  }
}
