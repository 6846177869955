@import "src/assets/scss/required";

.loadingWrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 1rem;
}

.selector {
  border: 1px solid $border-color;
  border-radius: 4px;
  color: $dark;
  font-size: 1rem;
}
