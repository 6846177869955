@import "src/assets/scss/required";
@import "~bootstrap/scss/utilities/spacing";
@import "~bootstrap/scss/utilities/display";
@import "~bootstrap/scss/utilities/borders";
@import "~bootstrap/scss/utilities/shadows";

.content {
  .card {
    @extend .card;
    border: 0 none;

    a {
      text-decoration: none !important;
      color: $dark;
    }
  }

  .devKey {
    .header {
      margin-top: $spacer;
      overflow: hidden;
      border: none;
      background: rgba($primary, .1);
      width: 100%;
      display: flex;
      padding: $spacer;
      align-items: center;

      > div {
        display: flex;
        flex-grow: 1;
        align-items: center;
      }
    }

    .footer {
      padding: $spacer;
      background: rgba($info, .07);
      display: flex;
      align-items: flex-end;

      &Content {
        flex-grow: 1;

        .msg {
          @extend .mb-1;
        }

        .path {
          @extend .d-flex;

          .os {
            font-size: 0.9rem;
            opacity: 0.8;
            width: 4.5rem;
          }

          .osValue {
            font-weight: 700;
          }
        }
      }
    }

    dl {
      display: flex;
      margin-right: $spacer*2;
      margin-bottom: 0;
    }

    dd {
      margin-right: $spacer/2;
      margin-bottom: 0;
    }
  }

  .devKeyLabel {
    width: 120px;

    > div {
      background: rgba($primary, .2);
      text-transform: uppercase;
      padding: $spacer/4 $spacer/2;
      line-height: 2;

      > svg {
        margin-right: $spacer/4;
        margin-top: -2px;
      }
    }
  }

  .devKeyOptions {
    @extend .shadow-none;
  }
}

.planbg {
  background: transparent url('../../../assets/img/plan-bg.jpg') no-repeat top right;
  background-size: cover;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 8px;
  padding: $spacer;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  margin-top: $spacer;
}
