@import "src/assets/scss/customVariables";

.body {
  padding: $spacer * 2;
  display: flex;
  flex-direction: column;
  align-items: center;

  .periodChanger {
    margin-bottom: $spacer * 2;
    display: flex;
    align-items: center;

    .monthly {
      color: $dark;
    }

    .annually {
      color: $info;
    }

    .toggle {
      margin-left: $spacer;
      margin-right: $spacer;
      display: flex;
      align-items: center;
    }

    .savePercentage {
      margin-left: $spacer * 0.3;
      font-size: 0.8rem;
      padding-bottom: 1rem;
      color: $primary;
    }
  }

  .plans {
    width: 100%;

    .plan {
      margin: $spacer * 0.1;
      padding: $spacer * 2;
      background: $dark;
      border-radius: 4px;

      .name {
        color: white;
        font-size: $spacer * 1.5;
        font-weight: bold;
        margin-bottom: $spacer;
      }

      .description {
        color: $info;
        margin-bottom: $spacer * 1.5;
      }

      .price {
        color: white;
        font-size: $spacer * 2;
        font-weight: bold;
      }

      .priceDesc {
        color: $info;
        margin-bottom: $spacer * 2;
      }

      .items {
        height: $spacer * 22;

        .item {
          margin-bottom: $spacer * 0.5;
          display: flex;
          align-items: center;

          .text {
            margin-left: $spacer * 0.4;
            color: white;
          }
        }
      }

      .cta {
        width: 100%;
        height: $spacer * 2.5;
        display: flex;
        align-items: center;
        justify-content: center;

        .check {
          margin-bottom: $spacer * 0.1;

          svg {
            path {
              stroke: white;
            }
          }
        }
      }

      &:last-child {
        background: #006ca5;

        .name, .price {
          color: white;
        }

        .description, .priceDesc {
          color: white;
          opacity: 0.6;
        }
      }
    }
  }
}
