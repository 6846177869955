@import "src/assets/scss/required";
@import "~bootstrap/scss/utilities/display";
@import "~bootstrap/scss/utilities/flex";
@import "~bootstrap/scss/utilities/spacing";

.osSelector {
  @extend .d-flex, .align-items-center, .mb-2;

  .selectOs {
    @extend .mr-3;
    display: block;
  }

  .selector {
    width: 8rem;
    border: 1px solid $border-color;
    border-radius: 4px;
    color: $dark;
  }
}

.step {
  border-bottom: 1px solid rgba($border-color, .2);
  padding-bottom: $spacer*3;
  padding-top: $spacer*3;
  margin-right: 200px;

  &:last-child {
    border-bottom: 0 none;
  }

  header {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: larger;
    cursor: pointer;

    svg {
      display: none;
    }

    a {
      font-size: smaller;
    }
  }

  &.done {
    padding-bottom: $spacer;
    padding-top: $spacer;

    header {
      color: $info;
      text-decoration: line-through;

      a {
        display: none;
      }

      span {
        display: none;
      }

      svg {
        display: block;
      }
    }
  }

  &.inactive {
    padding-bottom: $spacer;
    padding-top: $spacer;

    header {
      color: $info;

      .stepnumber {
        background: transparent;
        color: $info;
      }
    }
  }
}

.stepcta {
  white-space: nowrap;
}

.stepnumber {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background: $primary;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.stepbody {
  margin-left: 45px;
  margin-top: 15px;
}

.configpreview {
  padding: 1rem;
  width: 100%;
  height: 200px;
  border-color: $border-color;
  white-space: pre-wrap;
  border-radius: 4px;
  resize: none;
}

.netrcPreview {
  height: 100px;
}

.hostItem {
  display: flex;
  align-items: stretch;
  background: rgba($info, .15);
  height: 50px;
  border-radius: 8px;

  > * {
    display: flex;
    align-items: center;
    padding: 0 $spacer*1.5;
  }

  header {
    font-weight: 600;
    flex-grow: 1;

    i {
      width: 12px;
      height: 12px;
      border-radius: 100%;
      background: $primary;
      margin-right: $spacer;
    }
  }

  .hostFile {
    border: 1px solid #fff;
    border-width: 0px 1px 0px 1px;
    margin-right: -1px;
    color: $primary;
    font-weight: 600;
    text-decoration: none;
    cursor: pointer;

    svg {
      margin-right: $spacer/2;
    }

    &:hover {
      background: rgba($info, .05);
    }

    &:active {
      background: rgba($info, .2);
    }
  }
}
