// Import only required parts of Bootstrap. More parts must be added if needed
//@import '~bootstrap/scss/bootstrap.scss';
@import 'required';
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/media";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/utilities";


// custom CSS goes below
@font-face {
    font-family: 'Plusjakartasans';
    src: url('https://uploads-ssl.webflow.com/6044f6c929844b2a3add861f/6044f7e33f9b3a7916c97bdc_PlusJakartaSans-Regular.woff2') format('woff2'), url('https://uploads-ssl.webflow.com/6044f6c929844b2a3add861f/6044f7e33fdb24309ee17438_PlusJakartaSans-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Plusjakartasans';
    src: url('https://uploads-ssl.webflow.com/6044f6c929844b2a3add861f/6044f7e310212446125fc009_PlusJakartaSans-Medium.woff2') format('woff2'), url('https://uploads-ssl.webflow.com/6044f6c929844b2a3add861f/6044f7e3ae96da1029fae6c3_PlusJakartaSans-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Plusjakartasans';
    src: url('https://uploads-ssl.webflow.com/6044f6c929844b2a3add861f/6044f7e33ad5cbbeff4cf751_PlusJakartaSans-ExtraBold.woff2') format('woff2'), url('https://uploads-ssl.webflow.com/6044f6c929844b2a3add861f/6044f7e33a9c1767a8a5c334_PlusJakartaSans-ExtraBold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

body {
    font: 14px/1.4 'Plusjakartasans';
    background-color: $light;
    color: $dark;
    font-weight: 500;
}

p {
    font-weight: 500;
}

h1 {
    font-size: 34px;
    line-height: 120%;
    text-transform: capitalize;
    font-weight: 800;
}

h4 {
    font-size: 24px;
    line-height: 120%;
    font-weight: 800;
    text-transform: capitalize;
}

.authpage {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 90vh;
  background: url("https://uploads-ssl.webflow.com/6044f6c929844b2a3add861f/6045408a3a9c17521da77a6e_waves.png") no-repeat center 40vh;
  background-size: 80%;

  &.no-bg {
      background: none;
  }
}

.box {
    padding: $padding/1.5;
    box-shadow: 0px 3px 8px rgba($dark, 0.02);
    background: $white;
    border-radius: 4px;
    &.active{
        box-shadow: 0 0 0 2px $primary;
    }

    &.full-h {
        align-self: stretch;
    }
}

.authbox {
    @extend .box;
    margin-top: $padding;
    padding: 1.5*$padding;
}

.card-box {
    @extend .box;
    padding: 0;
    border: 0 none;
}

.authform {
  width: 350px;
}

.subheader {
  color: $primary;
  text-transform: uppercase;
  font-weight: 800;
}

.btn:not(.btn-link) {
    font-weight: 600;
    font-size: 13px;
    text-transform: uppercase;
    white-space: nowrap;
    box-shadow: 0px 4px 8px rgba($dark, 0.1);
    &:active{
        box-shadow: none;
    }
}

.btn-link {
    font-weight: 600;
}

code {
    background: $dark;
    padding: $spacer;
    border-radius: 8px;
    color: #fff;
    display: block;
}
.usermenu{
    a {
      display: flex;
      align-items: center;
      padding: $spacer/2 $spacer;
      color: #fff;
      opacity: .4;
      font-weight: 500;
      text-decoration: none;
      svg {
        margin-right: $spacer/2;
      }
      &.active {
        background: rgba(#fff, 0.1);
        opacity: 1;
        box-shadow: 4px 0px 0px 0px $primary inset;
      }
      &:not(.active):hover {
        //background: rgba(#fff, 0.1);
        opacity: .6;
      }
    }
  }

@include media-breakpoint-down(xs) {
    .authform, .authpage form, .authbox{
        width: 100%;
    }
    .authbox{
        padding: $spacer*2;
    }
}

.data-row{
    display: flex;
    align-items: center;
    align-items: stretch;
    > div {
        display: flex;
        align-items: center;
        padding: 0 5px 0 10px;
        border-bottom: 1px solid rgba($border-color,.2);
        min-height: 60px;
    }
    &.row-s{
        > div {
            min-height: 48px;
        }
    }
    &.row-clickable{
        cursor: pointer;
        &:hover {
            background: rgba($info,.05);
        }
    }
}

.row-functions {
    outline: 0;
    text-decoration: none;
    color: $info;
    &:focus{
      outline: 0;
    }
    &::after {
      display: none;
    }
  }

.host-status {
    display: block;
    width: 12px;
    height: 12px;
    background: $secondary;
    border-radius: 100%;
}

.avatar{
    display: inline-flex;
    width: 32px;
    height: 32px;
    background-color: $primary;
    border-radius: 100%;
    align-items: center;
    justify-content: center;
    color: $white;
    font-size: 14px;
    margin-bottom: 0;
    margin-right: $spacer/2;
}

.arrowback {
    cursor: pointer;
}
