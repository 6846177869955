@import "src/assets/scss/customVariables";

.content {
  padding: 3rem;

  .header {
    font-weight: bold;
    font-size: 1.2rem;
  }

  .plan {
    margin-top: $spacer4;

    .title {
      margin-bottom: $spacer3;
      font-weight: bold;
    }

    .change {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: $spacer * 0.5;

      .planName {
      }

      .cta {
        color: $primary;
        cursor: pointer;
        font-weight: bold;

        &:hover {
          text-decoration: underline;
        }
      }

      .options {
        box-shadow: none;
        padding-right: 0;
      }
    }

    .amount {
      opacity: 0.8;
      font-size: 0.8rem;
    }

    .schedule {
      margin-top: $spacer4;
    }

    .subscriptionsHistory {
      margin-top: $spacer4;
      opacity: 0.8;
      color: $primary;
      cursor: pointer;

      &:hover {
        opacity: 1;
        text-decoration: underline;
      }
    }
  }
}
