@import "src/assets/scss/required";
@import "~bootstrap/scss/utilities/display";
@import "~bootstrap/scss/utilities/flex";
@import "~bootstrap/scss/utilities/spacing";
@import "~bootstrap/scss/utilities/shadows";

.noResults {
  @extend .d-flex, .justify-content-center;
}

.loadingWrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 1rem;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: $spacer4;

  .start {
    display: flex;
    align-items: center;

    .title {
      font-weight: bold;
      font-size: 2.1rem;
    }
  }
}

.members {
  padding: 3rem;

  .header {
    font-weight: bold;
    font-size: 1.5rem;
  }

  .toolbar {
    @extend .d-flex, .justify-content-between, .align-items-center, .mb-4;
    font-size: 0.7rem;
    margin-left: -0.1rem;

    .sortAndSearch {
      @extend .d-flex;

      .sort {
        @extend .p-0, .mr-3;
        align-self: start;
        z-index: 50;

        div {
          font-size: 0.9rem;
        }

        button {
          @extend .shadow-none, .p-0;
          opacity: 0.6;

          &:after {
            content: none;
          }
        }
      }

      .search {
        @extend .p-1;
        width: 15rem;
        box-shadow: none;
        border-radius: 6px;
        border: 1px solid rgba(39, 42, 53, 0.3);
      }
    }

    .paginationInfo {
      @extend .d-flex;
      opacity: 0.6;

      .previousNext {
        @extend .d-flex, .align-items-center, .justify-content-between, .ml-2;
        width: 3rem;

        .previous,
        .next {
          font-size: 0.8rem;
        }
      }
    }
  }
}

.row {
  .name {
    width: 43%;
  }

  .username {
    width: 42%;
  }

  .role {
    width: 15%;
  }

  .placeholderOptions {
    width: 3.8rem;
  }
}

.tableHeader {
  @extend .row;
  opacity: 0.6;
}
