@import "src/assets/scss/required";
@import "node_modules/bootstrap/scss/utilities/display";
@import "node_modules/bootstrap/scss/utilities/flex";
@import "node_modules/bootstrap/scss/utilities/spacing";
@import "node_modules/bootstrap/scss/utilities/shadows";

.loadingWrapperOrError {
  display: flex;
  justify-content: center;
}

.toolbar {
  @extend .d-flex, .justify-content-between, .align-items-center, .my-4;
  font-size: 0.7rem;

  .sortAndSearch {
    @extend .d-flex;

    .sort {
      @extend .p-0, .mr-3;
      align-self: start;
      z-index: 50;

      div {
        font-size: 0.9rem;
      }

      button {
        @extend .shadow-none, .p-0;
        opacity: 0.6;

        &:after {
          content: none;
        }
      }
    }

    .search {
      @extend .p-1;
      width: 15rem;
      box-shadow: none;
      border-radius: 6px;
      border: 1px solid rgba(39, 42, 53, 0.3);
    }
  }

  .paginationInfo {
    @extend .d-flex;
    opacity: 0.6;

    .previousNext {
      @extend .d-flex, .align-items-center, .justify-content-between, .ml-2;
      width: 3rem;

      .previous,
      .next {
        font-size: 0.8rem;
      }
    }
  }
}

.userRow {
  .placeholderAvatar {
    width: 4rem;
  }

  .nameAndEmail {
    @extend .d-flex, .flex-column, .align-items-start, .justify-content-center;
    width: 50%;

    .email {
      opacity: 0.6;
    }
  }

  .createdDate {
    width: 20%;
  }

  .companyAndPosition {
    @extend .d-flex, .flex-column, .align-items-start, .justify-content-center;
    width: 30%;

    .position {
      opacity: 0.6;
    }
  }

  .role {
    width: 5rem;
  }

  .placeholderOptions {
    width: 4.4rem;
  }

  .options {
    box-shadow: none;
  }
}

.tableHeader {
  @extend .userRow;
  opacity: 0.6;
}
