@import "src/assets/scss/required";
@import "~bootstrap/scss/utilities/spacing";
@import "~bootstrap/scss/utilities/display";
@import "~bootstrap/scss/utilities/flex";

.content {
  .changelink {
    position: absolute;
    right: 0;
    top: $spacer*1.5;
    z-index: 1;
  }

  .selector {
    border: 1px solid $border-color;
    border-radius: 4px;
    color: $dark;
    font-size: 1rem;
  }
}
