@import "src/assets/scss/required";
@import "~bootstrap/scss/utilities/spacing";
@import "~bootstrap/scss/utilities/display";
@import "~bootstrap/scss/utilities/borders";
@import "~bootstrap/scss/utilities/shadows";

.content {
  @extend .px-0;
  display: flex;

  .sidebarWrapper {
    @extend .d-lg-block;
    height: 100vh;
    min-width: 190px;
    background: $dark;
    color: #fff;
    position: sticky;
    top: 0;

    .outbound {
      position: absolute;
      right: $spacer/2;
    }
  }

  .pageContentWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;

    .dropdown {
      button {
        @extend .shadow-none;
      }

      .divider {
        @extend .my-1;
      }

      .nameAndEmail {
        pointer-events: none;

        .name {
          font-size: 0.9rem;
          font-weight: 700;
        }

        .email {
          font-size: 0.8rem;
          opacity: 0.8;
        }
      }
    }

    .pageContent {
      display: flex;
      flex-grow: 1;
      padding: $spacer*2;
    }
  }

  .navi {
    background: $white;
  }
}
