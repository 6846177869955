@import "src/assets/scss/customVariables";

.body {
  padding: $spacer * 2;

  .invoice {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: $spacer2;
    padding-top: $spacer2;
    border-bottom: 1px solid rgba($border-color, .2);

    .options {
      box-shadow: none;
    }
  }
}
