@import "src/assets/scss/customVariables";

.body {
  padding: $spacer * 2;

  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: $spacer * 0.85;

    .plan {
      width: 23%;
    }

    .status {
      width: 26%;
    }

    .date {
      width: 8rem;
    }

    .options {
      box-shadow: none;
    }

    .placeholderOptions {
      width: 4rem;
    }
  }

  .tableHeader {
    @extend .item;
    opacity: 0.6;
  }
}
