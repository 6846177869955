.tryInvite {
  font-weight: bold;
  letter-spacing: 0.02em;
}

.ctas {
  display: flex;
  margin-top: 1.5rem;

  .cta {
    margin-left: auto;
  }
}
