//$white: #FFFFFF;

$primary: #27B4FF;
$secondary: #57F4C6;
$success: #57F4C6;
$info: #7F84B0;
// $warning: #EE8F38;
$danger: #FF3C75;
$light: #F3F4F8;
$dark: #0F1652;

$background-color: #F3F4F8;
$border-color: rgba($info,.5);

// Layout variables
$border-radius: 4px;
$padding: 30px;

// Spacing

$spacer: 1rem;

// for easier use in the app
$spacer0: 0;
$spacer1: $spacer * .25;
$spacer2: $spacer * .5;
$spacer3: $spacer;
$spacer4: $spacer * 1.5;
$spacer5: $spacer * 3;
