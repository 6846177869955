@import 'src/assets/scss/required';
@import '~bootstrap/scss/utilities/spacing';
@import '~bootstrap/scss/utilities/text';
@import '~bootstrap/scss/utilities/display';
@import '~bootstrap/scss/utilities/flex';

.expiredSession {
  @extend .font-weight-bold;
  color: $danger;
}

.pwlink {
  position: absolute;
  right: 0;
  top: $spacer/3;
}

.trustInfo {
  @extend .mt-3;
  width: 23rem;

  &Item {
    @extend .d-flex, .mt-3, .align-items-center;

    &Icon {
      @extend .mr-4;
    }

    &Text {
      @extend .mb-2;
      font-size: 0.65rem;
    }
  }
}
